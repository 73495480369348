// Функция для получения названия блока по уникальному параметру который характерен для блока
export function getBlockName(keys) {
  if (keys.includes("declaration_date")) {
    return "О декларации";
  } else if (keys.includes("declaration_kind_code")) {
    return "Основная информация";
  } else if (keys.includes("reg_number")) {
    return "Таможенная информация";
  } else if (keys.includes("cargo_route_json")) {
    return "Маршрут";
  } else if (keys.includes("sender_json")) {
    return "Отправитель";
  } else if (keys.includes("recipient_json")) {
    return "Получатель";
  } else if (keys.includes("principal_json")) {
    return "Декларант";
  } else if (keys.includes("carrier_json")) {
    return "Перевозчик";
  } else if (keys.includes("driver_json")) {
    return "Водитель";
  } else if (keys.includes("filler_json")) {
    return "Представленный";
  } else if (keys.includes("container_indicator")) {
    return "О транспорте";
  } else if (keys.includes("ware_range")) {
    return "Представленные документы";
  } else if (keys.includes("tn_ved")) {
    return "Товар";
  } else if (keys.includes("about_wares_json")) {
    return "Общие хар-ки товаров";
  } else if (keys.includes("destination_customs_json")) {
    return "Орган назначения";
  } else if (keys.includes("filler_ul_json")) {
    return "Лицо, предоставившее предварительную информацию";
  } else if (keys.includes("guarantee_present_code")) {
    return "Обеспечение";
  } else {
    return "БЛОК НЕ ЗАРЕГИСТРИРОВАН";
  }
}
