export function getBlockName(keys) {
  if (keys.includes("declaration_date")) {
    return "О декларации";
  } else if (keys.includes("about_transport_json")) {
    return "Основная информация";
  } else if (keys.includes("seller_json")) {
    return "Продавец ";
  } else if (keys.includes("buyer_json")) {
    return "Покупатель";
  } else if (keys.includes("declarant_json")) {
    return "Декларант";
  } else if (keys.includes("filler_json")) {
    return "Представленный";
  } else if (keys.includes("ware_range")) {
    return "Представленные документы";
  } 
  {
    return "БЛОК НЕ ЗАРЕГИСТРИРОВАН";
  }
}
